<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import { throttle } from "throttle-debounce";
import MainCheckbox from "@/components/helpers/MainCheckbox";
import MainButton from "@/components/helpers/MainButton";
import MainTitleBack from "@/components/helpers/MainTitleBack";
import TransferAmountInput from "@/components/transfers/TransferAmountInput.vue";
import TransferSelector from "@/components/transfers/TransferSelector.vue";
import TransferSuccess from "@/components/transfers/TransferSuccess";

export default {
  components: {
    MainTitleBack,
    MainButton,
    TransferSelector,
    TransferSuccess,
    TransferAmountInput,
    MainCheckbox,
  },

  data() {
    return {
      amount: "",
      transferStatus: "",
      loading: false,

      transferAmountError: false,
      transferAmountErrorMessage: "",

      isAccepted: false,
    };
  },

  watch: {
    amount() {
      if (this.amount && this.amount < 100) {
        this.transferAmountError = true;
        this.transferAmountErrorMessage = "Минимальная сумма - 100 RC";
      } else if (
        this.transferAccountFrom &&
        this.transferAccountFrom.summary_active < this.amount
      ) {
        this.transferAmountError = true;
        this.transferAmountErrorMessage = "Недостаточно средств";
      } else if (this.amount % 100 !== 0) {
        this.transferAmountError = true;
        this.transferAmountErrorMessage = "Сумма должна быть кратна 100";
      } else {
        this.transferAmountError = false;
        this.transferAmountErrorMessage = "";
      }
    },
  },

  computed: {
    ...mapState({
      transferAccountFrom: (state) => state.transfer.transferAccountFrom,
      transferAccountTo: (state) => state.transfer.transferAccountTo,
    }),

    ...mapGetters({
      getUserAccounts: "auth/getUserAccounts",
    }),

    filteredAccounts() {
      if (!this.transferAccountFrom) {
        return this.getUserAccounts;
      }

      let filteredAccounts;

      filteredAccounts = this.getUserAccounts.filter(
        (account) => account.id !== this.transferAccountFrom.id
      );

      switch (this.transferAccountFrom.user_type) {
        case "distributor":
          switch (this.transferAccountFrom.account_type) {
            case "rc":
              filteredAccounts = filteredAccounts.filter(
                (account) =>
                  account.user_type === "client" ||
                  (account.user_type === "distributor" &&
                    account.account_type === "cooperative")
              );
              break;

            case "cooperative":
              filteredAccounts = filteredAccounts.filter(
                (account) =>
                  account.user_type === "distributor" &&
                  account.account_type === "rc"
              );
              break;
          }
          break;

        case "client":
          filteredAccounts = filteredAccounts.filter(
            (account) =>
              account.user_type === "distributor" &&
              account.account_type === "rc"
          );
          break;
      }

      return filteredAccounts;
    },

    transferButtonText() {
      return this.loading ? "Переводим..." : "Перевести";
    },
  },

  methods: {
    ...mapMutations({
      setTransferAccountFrom: "transfer/SET_TRANSFER_ACCOUNT_FROM",
      setTransferAccountTo: "transfer/SET_TRANSFER_ACCOUNT_TO",
    }),

    ...mapActions({
      storeTransfer: "transfer/storeTransfer",
      checkUser: "auth/checkUser",
    }),

    selectTransferAccountFrom(account) {
      this.setTransferAccountFrom(account);
      this.setTransferAccountTo(null);
    },

    storeTransferRequest: throttle(5000, function () {
      this.loading = true;
      const data = {
        accountFromId: this.transferAccountFrom.id,
        accountToId: this.transferAccountTo.id,
        amount: this.amount,
      };

      this.storeTransfer(data).then((response) => {
        this.loading = false;

        if (response.data.message === "Трансфер совершен") {
          this.transferStatus = "success";
          this.checkUser();
        } else {
          this.transferStatus = "error";

          setTimeout(() => {
            this.transferStatus = "";
          }, 3000);
        }
      });
    }),
  },

  mounted() {
    this.setTransferAccountFrom(null);
    this.setTransferAccountTo(null);
  },
};
</script>

<template>
  <div class="transfer-to-account">
    <div v-if="transferStatus !== 'success'">
      <main-title-back title="Между своими счетами" href="Transfers" />

      <div class="transfer-to-account__block">
        <div class="transfer-to-account__selector">
          <transfer-selector
            label="Счет списания"
            :selected-account="transferAccountFrom"
            :accounts="getUserAccounts"
            @selectTransferAccount="selectTransferAccountFrom"
          />
        </div>
      </div>

      <div class="transfer-to-account__block">
        <div class="transfer-to-account__selector">
          <transfer-selector
            label="Счет начисления"
            :selected-account="transferAccountTo"
            :accounts="filteredAccounts"
            @selectTransferAccount="setTransferAccountTo"
          />
        </div>
      </div>

      <transfer-amount-input
        v-model="amount"
        class="transfer-to-account__amount"
        :error="transferAmountError"
        :error-message="transferAmountErrorMessage"
      />

      <main-checkbox
        v-model="isAccepted"
        :model-value="isAccepted"
        title="Совершая перевод я соглашаюсь с его"
        link-text="условиями и тарифами"
        href="/docs/Условия_и_тарифы.docx"
      />

      <div class="transfer-to-account__button">
        <main-button
          color="gold"
          :disabled="
            !amount ||
            !transferAccountFrom ||
            !transferAccountTo ||
            transferAmountError ||
            !isAccepted
          "
          :title="transferButtonText"
          @click="storeTransferRequest"
        />
      </div>

      <p v-if="transferStatus === 'error'" class="transfer-to-account__error">
        Что-то пошло не так, попробуйте снова
      </p>
    </div>

    <transfer-success
      v-if="transferStatus === 'success'"
      type="account"
      :amount="amount"
      :transfer-account-from="transferAccountFrom"
      :transfer-account-to="transferAccountTo"
    />
  </div>
</template>

<style scoped lang="scss">
.transfer-to-account {
  background-color: $background-white;
  padding: $space-xxl $space-xxl $space-xxxl;

  @media (max-width: 768px) {
    padding: $space-xxl $space-l 0;
  }

  @media (max-width: 375px) {
    padding: $space-xxl $space-m 0;
  }

  &__block {
    margin: 0 0 $space-xl;
  }

  &__amount {
    margin-bottom: $space-xl;
  }

  .main-checkbox {
    margin-bottom: $space-l;

    input {
      & + label {
        @include caption-1;
      }
    }
  }

  &__button {
    width: 300px;

    @media (max-width: 375px) {
      width: 100%;
    }

    @media (max-width: 1023px) {
      height: auto;
      padding: 0 0 $space-xxl;
    }
  }

  &__error {
    @include text-2;
    color: $red;
    margin-top: $space-m;
  }
}
</style>
